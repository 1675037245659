/* global process */
import React from "react";
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { linkResolver, transformFieldName } from '../utils/prismic-config';
import "../scss/claims-form.scss";

function ClaimsFormTemplate ({ data, pageContext }) { //eslint-disable-line no-unused-vars
  const doc = data.prismicClaimsForm.data;

  return (
    <>
      <Helmet>
        <title>{doc.title.text}</title>
        <meta name="og:title" content={doc.title.text} />
        <meta name="description" content={doc.metaDescription?.text} />
        <meta name="og:description" content={doc.metaDescription?.text} />
        <meta name="og:image" content={doc.image?.fixed?.src} />
      </Helmet>

      <pre><code>{JSON.stringify(doc, null, ' ')}</code></pre>

      <div className="wysiwyg-slice-content" dangerouslySetInnerHTML={{ __html: doc.carrierInformationContent.html }} />
    </>
  );
}

export const query = graphql `
query ClaimsFormQuery($id: String) {
  prismicClaimsForm(id: { eq: $id }) {
    _previewable

    data {
      title { text }
      metaDescription
      image {
        fixed { src }
      }

      headerColumns {
        column { html }
      }

      footerLinks {
        icon { gatsbyImageData }
        link { url }
        text
      }

      howToFileContent { html }
      startClaimContent { html }
      selectShipmentInstructions
      shipmentDetailsContent { html }
      claimantInformationContent { html }
      carrierInformationContent { html }
      claimInformationContent { html }
      shipmentInformationContent { html }
      statementOfLossContent { html }
      supportingDocumentsContent { html }
      documentation {
        content { html }
        formName
      }
      missingRequiredExplanationInstructions
      reviewClaimFormContent { html }
      reviewLegalContent { html }
      legalContent { html }
      signatureContent { html }

      confirmationButtons {
        icon { gatsbyImageData }
        link { url }
        text
        textBelow { html }
      }
      confirmationContent { html }
      confirmationFinalButtonLink { url }
      confirmationFinalButtonText
      confirmationImage { gatsbyImageData }

    }
  }
}
`;

export default withPrismicPreview(ClaimsFormTemplate, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY,
    linkResolver, transformFieldName
  }
]);
